<template>
  <div>
    <v-toolbar tile flat elevation="1">
      <v-btn @click="$router.go(-1)" class="no-active" icon>
        <v-icon>$vuetify.icons.arrow_left</v-icon>
      </v-btn>
      <v-toolbar-title>Смена пароля</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-container v-if="!loadPage" class="fill-height" fluid>
      <v-row align="center" justify="center">
        <v-col cols="12" md="8">
          <v-card class="elevation-0">
            <v-form v-model="valid" ref="form">
              <v-row>
                <!-- <div class="image-preview" v-if="imageData.length > 0"> -->

                <v-col cols="12">
                  <v-text-field
                    v-model="old_password"
                    prepend-icon="$vuetify.icons.lock"
                    :append-icon="
                      showOldPassword
                        ? '$vuetify.icons.eye'
                        : '$vuetify.icons.eye_off'
                    "
                    :type="showOldPassword ? 'text' : 'password'"
                    label="Выведите текущий Пароль"
                    @click:append="showOldPassword = !showOldPassword"
                    required
                  ></v-text-field>

                  <v-text-field
                    v-model="password"
                    prepend-icon="$vuetify.icons.lock"
                    :append-icon="
                      showPassword
                        ? '$vuetify.icons.eye'
                        : '$vuetify.icons.eye_off'
                    "
                    :type="showPassword ? 'text' : 'password'"
                    label="Пароль"
                    hint="Минимум 8 символов"
                    @click:append="showPassword = !showPassword"
                    :rules="oldPasswordRules"
                    required
                  ></v-text-field>

                  <v-text-field
                    v-model="password_confirmation"
                    prepend-icon="$vuetify.icons.lock_plus"
                    :append-icon="
                      showPassword2
                        ? '$vuetify.icons.eye'
                        : '$vuetify.icons.eye-off'
                    "
                    :type="showPassword2 ? 'text' : 'password'"
                    label="Повторите пароль"
                    hint="Минимум 8 символов"
                    @click:append="showPassword2 = !showPassword2"
                    :rules="confirmPasswordRules"
                    required
                  ></v-text-field>

                  <v-btn
                    class="mt-2"
                    dark
                    color="primary"
                    :loading="settingsLoading"
                    @click="submit"
                    >Обновить</v-btn
                  >
                </v-col>
              </v-row>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  components: {},
  data: () => ({
    valid: true,
    old_password: "",
    password: "",
    password_confirmation: "",
    showOldPassword: false,
    showPassword: false,
    showPassword2: false,
    requiredRules: [(v) => !!v || "Поле обязательно"],
    passwordRules: [
      (v) => !!v || "Поле обязательно",
      (v) => (v && v.length >= 8) || "Минимум 8 символов",
    ],
    settingsLoading: false,
  }),
  methods: {
    validate() {
      return this.$refs.form.validate();
    },
    submit() {
      if (!this.validate()) return;
      let app = this;
      app.settingsLoading = true;
      let data = {
        old_password: app.old_password,
        password: app.password,
        password_confirmation: app.password_confirmation,
      };

      let formData = new FormData();

      for (let key in data) {
        formData.append(key, data[key]);
      }

      formData.append("_method", "PUT"); //костыль для laravel
      this.$store
        .dispatch("account/updateAccountPassword", formData)
        .then(() => {
          app.settingsLoading = false;
        })
        .catch(() => {
          app.settingsLoading = false;
        });
    },
  },
  computed: {
    ...mapGetters(["currentUser", "loadPage"]),
    oldPasswordRules() {
      const rules = [];
      const rule = (v) => !!v || "Поле обязательно";
      const rule2 = (v) => (v && v.length >= 8) || "Минимум 8 символов";
      const rule3 = (v) =>
        (!!v && v) !== this.old_password ||
        "Старый пароль и новый не должны совпадать";

      rules.push(rule);
      rules.push(rule2);
      rules.push(rule3);

      return rules;
    },
    confirmPasswordRules() {
      const rules = [];

      const rule = (v) =>
        (!!v && v) === this.password || "Пароли должны совпадать";

      rules.push(rule);

      return rules;
    },
  },
  watch: {},
  mounted() {},
};
</script>
